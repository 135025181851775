<template>
  <a-collapse class="text-left">
    <a-collapse-panel key="usage">
      <span slot="header">如何使用</span>
      <div class="helper">
        <p>首先，根据你的发行版下载安装包（也可以选择 AppImage/Flatpak 这类通用包装系统）</p>
        <ul>
          <li><h3>flatpak 安装包</h3>
          <pre class="code">flatpak --user install btgamemanager_7.2.0_x86_64.flatpak</pre>
          </li>
          <li><h3>AppImage 可执行文件</h3>
          <pre class="code">chmod +x btgamemanager_7.2.0_x86_64.AppImage
./btgamemanager_7.2.0_x86_64.AppImage</pre>
          </li>
          <li><h3>ArchLinux(.pkg.tar.zst 安装包)</h3>
          <pre class="code">sudo pacman -U btgamemanager_7.2.0_x64.pkg.tar.zst</pre>
          </li>
          <li><h3>Ubuntu / Deepin / UOS</h3>
          <pre class="code"># 建议先将下载后的软件包移动到 /tmp 或者其他 _apt 用户有权限访问的目录
mv ./btgamemanager_7.2.0_amd64.deb /tmp/btgamemanager_7.2.0_amd64.deb
sudo apt-get install /tmp/btgamemanager_7.2.0_amd64.deb</pre>
          </li>
          <li><h3>Fedora / Centos Stream</h3>
          <pre class="code">sudo dnf localinstall ./btgamemanager_7.2.0_x86_64.rpm</pre>
          </li>
        </ul>
      </div>
    </a-collapse-panel>
    <a-collapse-panel key="ia32">
      <span slot="header">没找到 32位 安装包 / 压缩包？</span>
      <div class="helper">
        <h2>很抱歉，管家从 7.2.0 版本开始停止 32位 Linux 的支持</h2>
      </div>
    </a-collapse-panel>
    <a-collapse-panel key="Centos">
      <span slot="header">Centos 发行版报错 <code><strong>/lib64/libstdc++.so.6: version <span style="color:red">`CXXABI_1.3.8'</span> not found</strong></code></span>
      <div class="helper">
        <p>很抱歉，Centos6 / Centos7 发行版不受支持，因为它实在是太老了</p>
        <p>如果你真的想在上面运行百田游戏安全管家，请自行升级C运行库</p>
      </div>
    </a-collapse-panel>
  </a-collapse>
</template>

<script>
import { Collapse } from 'ant-design-vue'
export default {
  components: {
    'a-collapse': Collapse,
    'a-collapse-panel': Collapse.Panel
  },
}
</script>

<style scoped>
.text-left {
  text-align: left;
}
.helper {
  margin-left: 2rem;
  color: black;
  font-size: 1em;
}
.importent {
  color: red;
  font-weight: bold;
}
.code {
  border: rgba(255,255,255,0.2) 1px solid;
  padding: 0.5rem;
  margin-bottom: 3px;
  border-radius: 3px;
  counter-reset: line;
  background: #222;
}
@media (prefers-color-scheme: dark) {
  .helper {
    color: white;
  }
  .helper >>> h1, .helper >>> h2, .helper >>> h3, .helper >>> h4, .helper >>> h5, .helper >>> h6, .helper >>> p {
    color: rgba(255, 255, 255, 0.85);
  }
}
</style>
