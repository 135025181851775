<template>
  <a-collapse class="text-left">
    <a-collapse-panel key="1">
      <span slot="header">Chrome，Microsoft edge（<img src="~@/assets/helper/edge.jpg"/>） 拦截下载，提示“该文件可能会损害您的计算机”</span>
      <div class="helper">
        <p>当下载完成后，浏览器提示<br/><img src="~@/assets/helper/windows-download-issue-1.jpg"/></p>
        <p>原因：下载地址不受 谷歌/微软 信任</p>
        <p>解决方案：</p>
        <ol>
          <li>点击提示右边的“更多”(三个点)<br/><img src="~@/assets/helper/windows-download-issue-2.jpg"/></li>
          <li>在弹出的提示框中点击“保留”<br/><img src="~@/assets/helper/windows-download-issue-3.jpg"/></li>
          <li>点击 “显示详细信息∨” 后，点击 “仍然保留”<br/><img src="~@/assets/helper/windows-download-issue-4.jpg"/></li>
        </ol>
      </div>
    </a-collapse-panel>
    <a-collapse-panel key="2">
      <span slot="header">打开游戏时显示 "Adobe Flash Player 更新提示"</span>
      <div class="helper">
      <img src="~@/assets/helper/windows-flash-update.jpg"/><br /><br />
      <p>原因：Flash插件触发自带更新逻辑，同时因为<a href="https://www.flash.cn/fpSwitch" target="_blank">重橙决定不再支持 &lt;34.0.0.192 的版本</a></p>
      <p>解决方案：</p>
      <ul>
        <li><h3>未触发玩家</h3>
        <ol><li>通过重新安装的方式更新到 v7.1.0 及更高版本</li></ol>
        </li>
        <li><h3>已触发玩家</h3>
        <ol><li>通过重新安装的方式更新到 v7.1.0 及更高版本</li>
        <li>删除 <code>%USERPROFILE%\AppData\Roaming\btgamemanager</code> 文件夹
        <br />
        <h3 class="importent">注意：这样做会清空本地缓存的所有数据，包括但不限于：</h3><ul>
          <li class="importent">已记住的账号，密码</li>
          <li class="importent">管家设置窗口的所有配置项</li>
          <li>本地缓存的所有资源（影响加载速度）</li>
          </ul>
          <span>操作步骤：</span>
          <br />
          <span>1. 打开 “我的电脑“</span>
          <br />
          <span>2. 将 <code>%USERPROFILE%\AppData\Roaming\btgamemanager</code> 粘贴到导航栏中</span>
          <br />
          <img src="~@/assets/helper/windows-delete-flash-ood-1.jpg"/>
          <br />
          <span>3. 按下回车键，进入该文件夹</span>
          <br />
          <span>4. 选中文件夹内的所有文件</span>
          <br />
          <img src="~@/assets/helper/windows-delete-flash-ood-2.jpg"/>
          <br />
          <span>5. 右键删除 或 按下 Delete 键删除</span>
          <br />
          <img src="~@/assets/helper/windows-delete-flash-ood-3.jpg"/>
          <br />
          <span>6. 当看到一个空文件夹时，代表问题已解决</span>
          <br />
          <img src="~@/assets/helper/windows-delete-flash-ood-4.jpg"/>
          <br />
          </li></ol>
        </li>
      </ul>
      </div>
    </a-collapse-panel>
  </a-collapse>
</template>

<script>
import { Collapse } from 'ant-design-vue'
export default {
  components: {
    'a-collapse': Collapse,
    'a-collapse-panel': Collapse.Panel
  },
}
</script>

<style scoped>
.text-left {
  text-align: left;
}
.helper {
  margin-left: 2rem;
  color: black;
  font-size: 1em;
}
.importent {
  color: red;
  font-weight: bold;
}
@media (prefers-color-scheme: dark) {
  .helper {
    color: white;
  }
  .helper >>> h1, .helper >>> h2, .helper >>> h3, .helper >>> h4, .helper >>> h5, .helper >>> h6, .helper >>> p {
    color: rgba(255, 255, 255, 0.85);
  }
}
</style>