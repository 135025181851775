import { render, staticRenderFns } from "./MacOS.vue?vue&type=template&id=567cfa2c&scoped=true&"
import script from "./MacOS.vue?vue&type=script&lang=js&"
export * from "./MacOS.vue?vue&type=script&lang=js&"
import style0 from "./MacOS.vue?vue&type=style&index=0&id=567cfa2c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "567cfa2c",
  null
  
)

export default component.exports