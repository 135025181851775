<template>
  <a-collapse class="text-left">
    <a-collapse-panel key="install">
      <span slot="header">如何安装</span>
      <div class="helper">
      <p>点击下载后，如果是第一次下载，会询问以下问题，点击允许即可<br/><img src="~@/assets/helper/mac-allow-download.jpg" width="420px"/></p>
      <ul>
        <li><h2>dmg安装包</h2>
          <ol>
            <li>下载dmg安装包后，默认会存放在 “Finder” 中的 “下载” 文件夹中</li>
            <li>双击（或右键打开）dmg文件，等待进度条跑完<br /><img src="~@/assets/helper/mac-opening-dmg.jpg" width="520px"/></li>
            <li>打开dmg文件后，会出现一个窗口，将app拖入Application中即可完成安装<br /><img src="~@/assets/helper/mac-dmg-install-window.jpg" width="520px"/></li>
          </ol>
        </li>
        <li><h2>zip压缩包</h2>
          <ol>
            <li>下载zip安装包后，系统可能会自动将其解压<br /><img src="~@/assets/helper/mac-downloaded-zip.jpg" width="520px"/><br />默认会存放在 “Finder” 中的 “下载” 文件夹中</li>
            <li>找到解压后的应用程序并将其拖入 “应用程序” 目录中即可完成安装<br /><img src="~@/assets/helper/mac-finder-app.jpg" width="113px"/></li>
          </ol>
        </li>
      </ul>
      </div>
    </a-collapse-panel>
    <a-collapse-panel key="security">
      <span slot="header">无法打开“百田游戏安全管家”，因为无法验证开发者</span>
      <div class="helper">
        <p>下载安装后，双击打开管家时，提示<br/><img src="~@/assets/helper/mac-security-ask-1.jpg" width="422px"/></p>
        <p>原因：没有写入受 MacOS 信任的开发者信息 或 下载地址不受 MacOS 信任</p>
        <p>解决方案：</p>
        <ol>
          <li>打开”系统偏好设置“，进入“安全性与隐私”<br/><img src="~@/assets/helper/mac-security-ask-2.jpg" width="670px"/></li>
          <li>在”通用“选项卡的下方可以看到<br/><span>已阻止使用“百田游戏安全管家”，因为来自身份不明的开发者</span><br/><img src="~@/assets/helper/mac-security-ask-3.jpg" width="669px"/></li>
          <li>点击“仍要打开”按钮</li>
          <li>确认下载地址为 <strong style="color: #1890ff"><code> https://*.100bt.com </code></strong> 或
            <strong style="color: #1890ff"><code> https://*.a0bi.com </code></strong>
            后点击“打开”按钮即可<br><img src="~@/assets/helper/mac-security-ask-4.jpg" width="447px"/></li>
        </ol>
      </div>
    </a-collapse-panel>
  </a-collapse>
</template>

<script>
import { Collapse } from 'ant-design-vue'
export default {
  components: {
    'a-collapse': Collapse,
    'a-collapse-panel': Collapse.Panel
  },
}
</script>

<style scoped>
.text-left {
  text-align: left;
}
.helper {
  margin-left: 2rem;
  color: black;
  font-size: 1em;
}
.importent {
  color: red;
  font-weight: bold;
}
@media (prefers-color-scheme: dark) {
  .helper {
    color: white;
  }
  .helper >>> h1, .helper >>> h2, .helper >>> h3, .helper >>> h4, .helper >>> h5, .helper >>> h6, .helper >>> p {
    color: rgba(255, 255, 255, 0.85);
  }
}
</style>