<template>
  <div>
    <a-button shape="circle" icon="arrow-left" class="return-btn" @click="$router.push('/')" />
    <a-tabs v-model="selectPlatform" class="helper-tabs">
      <a-tab-pane key="Windows">
        <span slot="tab">
          <a-icon type="windows"></a-icon>Windows系统
        </span>
        <Windows />
      </a-tab-pane>
      <a-tab-pane key="MacOS">
        <span slot="tab">
          <a-icon type="apple"></a-icon>MacOS系统
        </span>
        <MacOS />
      </a-tab-pane>
      <a-tab-pane key="Linux">
        <span slot="tab">
          Linux各发行版
        </span>
        <Linux />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { Icon, Tabs, Button } from 'ant-design-vue'
import Windows from './Helper/Windows.vue'
import Linux from './Helper/Linux.vue'
import MacOS from './Helper/MacOS.vue'
export default {
  components: {
    'a-icon': Icon,
    'a-tab-pane': Tabs.TabPane,
    'a-tabs': Tabs,
    'a-button': Button,
    Windows,
    Linux,
    MacOS,
  },
  data() {
    return {
      selectPlatform: this.getPlatform(),
    }
  },
  methods: {
    getPlatform() {
      const isWin = navigator.userAgent.includes('Windows NT')
      const isMac = navigator.userAgent.includes('Mac OS X')
      const isLinux = navigator.userAgent.includes('Linux')
      let selectPlatform
      if (isWin) selectPlatform = 'Windows'
      else if (isMac) selectPlatform = 'MacOS'
      else if (isLinux) selectPlatform = 'Linux'
      return selectPlatform
    },
  }
}
</script>

<style scoped>
.return-btn {
  background-color: transparent;
  color: white;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
}
.text-left {
  text-align: left;
}
.helper {
  margin-left: 2rem;
  color: black;
  font-size: 1em;
}
@media (prefers-color-scheme: dark) {
  .helper-tabs {
    background-color: rgb(22,27,34) !important;
    color: white;
  }
  .helper-tabs >>> .ant-collapse, .helper-tabs >>> .ant-collapse-content {
    background-color: rgb(32,37,44) !important;
  }
  .helper-tabs >>> .ant-collapse .ant-collapse-header {
    color: white;
  }
}
.importent {
  color: red;
  font-weight: bold;
}
h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.2rem;
}
</style>